<template>
  <b-row no-gutters
    ><b-col cols="4" class="text-left"
      ><span class="text-uppercase medium grey-subheading">{{
        $t("events.olympics.tutorial.general_info")
      }}</span></b-col
    ><b-col cols="8" class="text-left mb-5"
      ><span class="text-uppercase green bold larger">{{
        $t("events.olympics.tutorial.page_7.headline")
      }}</span>
      <p class="text-white medium large mt-3">
        {{ $t("events.olympics.tutorial.page_7.par_1") }}
      </p>
      <p class="green medium large mt-3">
        {{ $t("events.olympics.tutorial.page_7.par_2") }}
      </p></b-col
    ><b-col cols="4"></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon cart-grey"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.bundle_available")
      }}</span></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon cart-green"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.bundle_received")
      }}</span></b-col
    ><b-col cols="4"></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon medal-grey"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.medals_missing")
      }}</span></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon medal-green"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.medals_received")
      }}</span></b-col
    ><b-col cols="4"></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon gift-grey"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.bonus_missing")
      }}</span></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon gift-green"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.bonus_received")
      }}</span></b-col
    ><b-col cols="4"></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon cart-red"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.no_purchase")
      }}</span></b-col
    ><b-col cols="4" class="icon-col"
      ><div class="icon gift-gold"></div>
      <span>{{
        $t("events.olympics.tutorial.page_7.bonus_collected")
      }}</span></b-col
    ></b-row
  >
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage7",
};
</script>
<style scoped lang="scss">
.icon-col {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 24px;
  font-family: Ubuntu-medium;
  margin-bottom: 16px;
  text-align: left;
  .icon {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    &.cart-grey {
      background: url(~@/assets/img/events/gfg-basket-inactive.svg) center/30px
        26px no-repeat;
    }
    &.cart-green {
      background: url(~@/assets/img/events/gfg-icn-basket-active.svg)
        center/30px 26px no-repeat;
    }
    &.cart-red {
      background: url(~@/assets/img/events/gfg-icn-basket-nobuy.svg) center/30px
        26px no-repeat;
    }

    &.medal-grey {
      background: url(~@/assets/img/events/gfg-medal-inactive.svg) center/19px
        27px no-repeat;
    }
    &.medal-green {
      background: url(~@/assets/img/events/gfg-medal-active.svg) center/19px
        27px no-repeat;
    }
    &.gift-grey {
      background: url(~@/assets/img/events/gfg-gift-inactive.svg) center/27px
        27px no-repeat;
    }
    &.gift-green {
      background: url(~@/assets/img/events/gfg-gift-active.svg) center/27px 27px
        no-repeat;
    }
    &.gift-gold {
      background: url(~@/assets/img/events/gfg-gift-collected.svg) center/27px
        27px no-repeat;
    }
  }
}
</style>
